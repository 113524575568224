import * as React from 'react'
import { Link, graphql } from 'gatsby'
import Layout2 from '../../components/layout2'
import Seo2 from '../../components/seo2'

const IndexPage = () => {
  return (
    <Layout2 pageTitle="Plants">
      <p>Plants are so healthy for the soul. Watching and growing plants nurtures peace and tranquility in my day.</p>
      </Layout2>
  )
}


export const Head = () => <Seo2 title="My Plants" />

export default IndexPage